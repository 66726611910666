<template>
  <LayoutModal title="Contact" @modal-close="$emit('modal-close')">
    <div class="md:w-96">
      <div v-if="loading" class="flex items-center justify-center">
        <CommonLoading />
      </div>
      <div
        v-else-if="error"
        class="flex flex-col items-center justify-center py-8"
      >
        <CommonFontAwesomeIcon
          class="text-5xl text-red mb-6"
          icon="fa-regular fa-xmark-large"
        />
        <h2 class="font-sans text-xl font-medium">{{ $t('errorOccurred') }}</h2>
        <span class="text-sm text-gray-600 leading-5 mt-2">{{
          $t('errorOccurred1')
        }}</span>
      </div>
      <template v-else>
        <template v-if="step === 0 && !loading && !error">
          <div>
            <form
              ref="contactForm"
              @submit.prevent="callme()"
              class="grid gap-4 mt-4 mb-6"
            >
              <FormInput
                v-model="lead.customerName"
                type="text"
                required
                placeholder="NameHelpText"
                label="Name"
              />
              <FormInput
                v-model="lead.customerEmail"
                type="email"
                required
                placeholder="Email"
                label="Email"
              />
              <FormInput
                v-model="lead.customerTelephoneNumber"
                type="tel"
                required
                placeholder="Phone"
                :pattern="config.public.site.formValidation?.phonePattern"
                label="Phone"
              />

              <div class="flex items-start justify-start">
                <CommonPermissionBox
                  class="text-left mt-1"
                  :permissionGiven="marketingPermissionGiven"
                  @update:permission="updateMarketingPermission"
                />
              </div>
            </form>
          </div>
          <div class="border-t -mx-6 -mb-6 p-4 border-gray-300">
            <CommonButton
              bg="bg-blue"
              bgHover="hover:bg-denimblue"
              text="text-white"
              textHover="text-white"
              border="border-none"
              class="w-full mt-2"
              size="xlarge"
              @click="callme()"
              :disabled="!contactForm?.checkValidity()"
            >
              {{ $t('ads.callMe') }}
            </CommonButton>
          </div>
          <p
            class="md:w-96 text-sm text-gray-600 mt-6 mb-4 leading-snug text-center"
          >
            {{ $t('youWillBeContactedAt') }}
          </p>
        </template>
        <template v-else>
          <div class="flex flex-col items-center justify-center py-8">
            <CommonFontAwesomeIcon
              class="text-5xl text-grassgreen mb-6"
              icon="fa-light fa-circle-check"
            />
            <h2 class="font-sans text-xl font-medium">
              {{ $t('thankYouForContact') }}
            </h2>
            <p class="text-sm text-gray-600 leading-5 mt-2 text-center">
              {{ $t('youWillBeContactedAt') }}
            </p>
          </div>
          <div class="border-t -mx-6 -mb-6 p-4 border-gray-300">
            <CommonButton
              text="text-black"
              border="gray-300"
              class="w-full"
              @click="$emit('modal-close')"
            >
              {{ $t('okLowercaseK') }}
            </CommonButton>
          </div>
        </template>
      </template>
    </div>
  </LayoutModal>
</template>

<script setup lang="ts">
import type { Lead } from '~/typesAuto/apicore/v1'

const route = useRoute()
const dataLayer = useDataLayer()
const config = useRuntimeConfig()
const nuxtApp = useNuxtApp()

const props = defineProps<{
  submitLead: (lead: Lead) => Promise<boolean>
}>()

onMounted(() => {
  dataLayer.commercialSites.contactMeOpen(route.fullPath)
})

const lead = ref({
  customerName: '',
  customerEmail: '',
  customerTelephoneNumber: '',
  utmSource: route.query?.utm_source?.toString(),
  utmMedium: route.query?.utm_medium?.toString(),
  utmCampaign: route.query?.utm_campaign?.toString(),
  utmContent: route.query?.utm_Content?.toString(),
})

const step = ref(0)
const error = ref(false)
const loading = ref(false)
const contactForm = ref<HTMLFormElement>()
const marketingPermissionGiven = ref(false)

async function callme() {
  if (!contactForm.value?.checkValidity()) {
    return
  }

  loading.value = true
  const success = await props.submitLead(lead.value)
  loading.value = false
  if (success) {
    step.value = 1

    if (marketingPermissionGiven.value) {
      const { firstName, lastName } = splitUserName(
        lead.value.customerName || ''
      )
      const { err } = await nuxtApp.$api.user.addMarketingPermission({
        firstName: firstName,
        lastName: lastName ? lastName : null,
        email: lead.value.customerEmail,
        phone: lead.value.customerTelephoneNumber,
        permissionSource: 'Lead Form',
        permissionSourceUrl: window.location.href,
      })
      if (err) {
        console.error(err.message, ': ', err.cause)
      }
    }

    dataLayer.commercialSites.contactMeSubmit(route.fullPath, lead)
  } else {
    error.value = true
  }
}

const updateMarketingPermission = (newValue: boolean) => {
  marketingPermissionGiven.value = newValue
}
</script>
